<template>
  <base-material-card :icon="resource.icon" :title="title">
    <template v-slot:subtitle>
      <info-text :resource="resource" crud-type="list" />
    </template>
    <va-list :filters="filters" :sort-by="['date_from']" :sort-desc="[true]" disable-export>
      <template v-slot:actions="slotProps">
        <export-poll-button :resource="resource.name" text :options="slotProps.listState.options" :filter="slotProps.currentFilter" />
      </template>
      <va-data-table :fields="fields" disable-clone disable-delete disable-show>
        <template v-slot:[`item.actions`]="{ item }">
          <link-to-commands-button :item="item" />
          <link-button :label="$t('integrations.campaign.product.title.list')" :route="{ name: 'campaignProducts', params: { code: item.code } }" />
        </template>
        <template v-slot:[`field.order_fulfillment_count`]="{ item, value }">
          <router-link
            v-if="value"
            :to="{
              name: `orderFulfillments_list`,
              query: {
                filter: JSON.stringify({
                  campaign: item.code,
                  test: 'false'
                })
              }
            }"
            >{{ value }}</router-link
          >
          <span v-else>{{ value }}</span>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import i18n from "@/i18n";
import CampaignStatusVariants from "@/resources/campaigns/CampaignStatusVariants";
import CampaignTypeVariants from "@/resources/campaigns/CampaignTypeVariants";
import LinkToCommandsButton from "@/components/campaigns/LinkToCommandsButton";
import LinkButton from "@/components/actions/LinkButton";
import InfoText from "@/components/helper/InfoText";
import ExportPollButton from "../../components/export/ExportPollButton";

export default {
  components: { ExportPollButton, InfoText, LinkButton, LinkToCommandsButton },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        {
          label: i18n.t("integrations.fields.code"),
          source: "code"
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: CampaignTypeVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "status",
          type: "select",
          attributes: {
            choices: CampaignStatusVariants,
            itemText: "name",
            itemValue: "id"
          }
        },
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel_data",
          type: "reference",
          attributes: {
            reference: "channels",
            action: null
          }
        },
        {
          label: i18n.t("integrations.fields.date_from"),
          source: "date_from_utc",
          sortable: true,
          type: "function",
          attributes: {
            callback: value => {
              const date = new Date(Date.parse(value));
              return this.$d(date, "datetime");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.date_to"),
          source: "date_to_utc",
          sortable: true,
          type: "function",
          attributes: {
            callback: value => {
              const date = new Date(Date.parse(value));
              return this.$d(date, "datetime");
            }
          }
        },
        {
          label: i18n.t("integrations.fields.orderfulfillments"),
          source: "order_fulfillment_count",
          type: "number"
        }
      ],
      filters: [
        {
          label: i18n.t("integrations.fields.channel"),
          source: "channel",
          type: "autocomplete",
          alwaysOn: true,
          resettable: true,
          attributes: {
            reference: "channels_globals_as_defaults",
            searchQuery: "q",
            sortBy: ["name"]
          }
        },
        {
          label: i18n.t("integrations.fields.status"),
          source: "status",
          type: "select",
          attributes: {
            choices: CampaignStatusVariants,
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        },
        {
          label: i18n.t("integrations.fields.type"),
          source: "type",
          type: "select",
          attributes: {
            choices: CampaignTypeVariants,
            itemText: "name",
            itemValue: "id"
          },
          alwaysOn: true,
          resettable: true
        }
      ]
    };
  }
};
</script>
